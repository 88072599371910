import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "options", "radioPrompt", "questionOptionButton"]

  connect() {
    if (this.optionElements().includes(this.selectTarget.value)) {
      this.showOptions()
    } else {
      this.hideOptions()
    }
    this.toggleRadioPrompt()
  }

  toggleOptions(event) {
    if (this.optionElements().includes(event.target.value)) {
      this.showOptions()
    } else {
      this.hideOptions()
    }
  }

  showOptions() {
    this.optionsTarget.classList.remove("hidden")
  }

  hideOptions() {
    this.optionsTarget.classList.add("hidden")
  }

  toggleRadioPrompt() {
    if (this.selectTarget.value === "radio") {
      this.radioPromptTarget.classList.remove("hidden")
    } else {
      this.radioPromptTarget.classList.add("hidden")
    }
  }

  optionElements() {
    return ["select", "checkbox"]
  }
}
