import { Controller } from "@hotwired/stimulus"
let debounce = require("lodash.debounce")

// Connects to data-controller="search-form"
export default class extends Controller {
  static targets = ["form", "input"]

  initialize() {
    this.search = debounce(this.search, 200).bind(this)
    // auto submit search
  }

  search() {
    this.formTarget.requestSubmit()
  }

  clearInput() {
    this.inputTarget.value = ""
    this.inputTarget.focus()
  }

}
