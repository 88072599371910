import { Controller } from "@hotwired/stimulus"
import {
  findElement,
  removeElement,
  insertAfter,
} from "../helpers/dropzone";

export default class extends Controller {
  static targets = ["referenceImage"]
  static values = {
    imageId: String
  }

  removeImage(event) {
    console.log('remove image')
    let imageId = event.target.closest('button').dataset.artistSubscriberFormImageIdValue

    let input = document.createElement("input")
    input.type = "hidden"
    input.name = "subscriber[remove_image][]"
    input.value = imageId
    let textAlertCheckBox = document.getElementsByName("subscriber[text_alerts]")[0]
    insertAfter(input, textAlertCheckBox)
    removeElement(event.target.closest('.reference-image-preview'))
    this.showUpdateHelperText()
  }

  showUpdateHelperText() {
    let el = findElement('.remove-image-helper-text')
    el.hidden = false
  }

}
