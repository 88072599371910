import { Controller } from "@hotwired/stimulus"
import mapboxgl from 'mapbox-gl'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as turf from '@turf/turf'

export default class extends Controller {
  static values = {
    lat: String,
    lng: String,
    radius: String,
    contacts: Array,
  }

  connect() {
    mapboxgl.accessToken = 'pk.eyJ1Ijoic3BvdHNxdWlkIiwiYSI6ImNscTN2M3hyNTAwZnEyaWxzOHBuMzJoMDIifQ.cuJqLemGcXnd0-lX7BVjFg';
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [this.lngValue, this.latValue],
      zoom: this.setZoom(this.radiusValue),
    });

    const marker = new mapboxgl.Marker()
      .setLngLat([this.lngValue, this.latValue])
      .setPopup(new mapboxgl.Popup().setHTML("<p>Your Shop.  Use the search bar to find a new starting location</p>"))
      .addTo(map);

    this.contactsValue.forEach((contact) => {
      let contactMarker = new mapboxgl.Marker()
        .setLngLat([contact[1], contact[0]])
        .setPopup(new mapboxgl.Popup().setHTML(this.contactLink(contact)))
        .addTo(map);
    })


    const geocoder = new MapboxGeocoder({
                      accessToken: mapboxgl.accessToken,
                      mapboxgl: mapboxgl,
                      reverseGeocode: true,
                      flyTo: false
                    })

    // Add geocoder search bar
    map.addControl(geocoder);
    // Add the control to the map.
    map.addControl(new mapboxgl.NavigationControl());


    let lat = this.latValue;
    let lng = this.lngValue;
    let parsedRadius = Number(this.radiusValue);

    map.on('load', function () {
      let center_coords = [ lng, lat ]
      let center = turf.point(center_coords);
      let radius = parsedRadius; // In Miles
      let options = {steps: 100, units: 'miles'};

      let circle = turf.circle(center, radius, options);

      map.addSource('circleSource', {
          "type": "geojson",
          "data": circle
      });

      map.addLayer({
          "id": "circleLayer",
          "type": "fill",
          "source": "circleSource",
          "layout": {},
          "paint": {
              "fill-color": "blue",
              "fill-opacity": 0.6
          }
      });
    });

    geocoder.on('result', function(e) {
      document.getElementById('coordinates').value = e.result.center.reverse()
      // set form value
      document.getElementById('contact-map-search').requestSubmit();
      // submit form
    })
  }

  setZoom(radius) {
    if (radius <= 5) {
      return 11
    } else if (radius > 5 && radius <= 15) {
      return 10
    } else if (radius > 15 && radius < 40) {
      return 9
    } else if (radius > 40 && radius < 75) {
      return 8
    } else {
      return 7
    }
  }

  contactUrl(contact_id) {
    return `/contacts/${contact_id}`
  }

  contactLink(contact) {
    let linkForContact = this.contactUrl(contact[4])
    return `<a href=${linkForContact} target="_top">${contact[2]} ${contact[3]}</a>`
  }
}
