import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
  }

  connect() {
    document.body.classList.remove('bg-gray-50')
    document.body.classList.add('bg-[#050304]')
    this.element.click()
  }
}
