import { Controller } from "@hotwired/stimulus"
let debounce = require("lodash.debounce")

// Connects to data-controller="search-form"
export default class extends Controller {
  static targets = ["range", "rangeValue", "form"]

  connect() {
    console.log(this.rangeTarget.value)
    // if (this.rangeTarget.value) {
    this.setRangeValue()
  }

  // initialize() {
    // this.search = debounce(this.search, 500).bind(this)
    // auto submit search
  // }

  setRangeValue() {
    console.log(this.rangeTarget.value)
    this.rangeValueTarget.innerHTML = this.rangeTarget.value
  }

  search() {
    this.formTarget.requestSubmit()
  }
}
