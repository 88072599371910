import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search-form"
export default class extends Controller {
  static values = {
    tagIds: Array
  }

  connect() {
    let searchForm = document.getElementById('contacts-search-form')

    let tagIds = this.tagIdsValue
    if (tagIds.length > 0) {
      this.addTagIdsToForm(searchForm, tagIds)
    }
  }

  addTagIdsToForm(searchForm, tagIds) {
    tagIds.forEach(tagId => {
      let input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'tag_ids[]'
      let input_id = `tag_ids_${tagId}`
      input.id = input_id
      input.value = tagId
      if (!document.getElementById(input_id)){
        searchForm.appendChild(input)
      }
    })
  }

}
