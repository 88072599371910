import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "template" ]

  connect() {
    console.log('Connected to broadcast controller')
  }

  setTemplate(event) {
    this.templateTarget.value = event.params.name
    let selected = document.getElementsByClassName('border-indigo-500')
    debugger
    if (selected.length > 0) {
      selected[0].classList.add('border-slate-300', 'border')
      selected[0].classList.remove('border-indigo-500', 'border-2')
      event.target.classList.add('border-indigo-500', 'border-2')
      event.target.classList.remove('border-slate-300', 'border')
    } else {
      event.target.classList.remove('border-slate-300', 'border')
      event.target.classList.add('border-indigo-500', 'border-2')
    }
  }
}
