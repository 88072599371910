import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "menuOverlay" ]

  connect() {
  }

  toggle() {
    console.log('toggle')
    // not really sure what this is doing 
    this.menuOverlayTarget.classList.toggle('is-active')
    this.menuOverlayTarget.classList.toggle('hidden')
    if (this.menuOverlayTarget.classList.contains('is-active')) {
      // mobile menu displaying behind elements on some staic pages
      this.menuOverlayTarget.classList.add('z-20')
    }
  }

  closeMenu() {
    console.log('closeMenu')
    this.menuOverlayTarget.classList.toggle('is-active')
    this.menuOverlayTarget.classList.toggle('hidden')

    if (!this.menuOverlayTarget.classList.contains('is-active')) {
      // mobile menu displaying behind elements on some staic pages
      this.menuOverlayTarget.classList.remove('z-20')
    }
  }
  // Mobile menu, show/hide based on menu open state.

  // Entering: "duration-150 ease-out"
  //   From: "opacity-0 scale-95"
  //   To: "opacity-100 scale-100"
  // Leaving: "duration-100 ease-in"
  //   From: "opacity-100 scale-100"
  //   To: "opacity-0 scale-95"
}
